
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App {
  background-color: #F5F5F5;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.split {
  /* padding-right: 20px; */
  /* min-height: 100vh; */
}


/* Control the left side */
.left {
  background-color: #5555FF;
  box-shadow: 2px 1px 6px #979797;
}

/* Control the left side */
.mobileLeft {
  width: 50%;
  left: 0;
  height: 100%;
  background-color: #5555FF;
  box-shadow: 2px 1px 6px #979797;
  position: relative;
  /* padding-right: 20px; */
}


/* Control the right side */
.right {
  min-width: 40%;
  right: 0;
  background-color: #F6F6F6;
  overflow-x: auto;
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic 1.2s infinite linear;
  margin-left: 10px;
  margin-right: 10PX;
}
.dot-elastic::before, .dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic-before 1.2s infinite linear;
}
.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic-after 1.2s infinite linear;
}


.dot-elastic1 {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic 1.2s infinite linear;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 10PX;
}
.dot-elastic1::before, .dot-elastic1::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic1::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic-before 1.2s infinite linear;
}
.dot-elastic1::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555FF;
  color: #5555FF;
  animation: dot-elastic-after 1.2s infinite linear;
}


.dot-elastic-white {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FFFFFF;
  animation: dot-elastic 1.2s infinite linear;
  margin-left: 20px;
  margin-top: 5px;
}
.dot-elastic-white::before, .dot-elastic-white::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic-white::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FFFFFF;
  animation: dot-elastic-before 1.2s infinite linear;
}
.dot-elastic-white::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #FFFFFF;
  animation: dot-elastic-after 1.2s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.6);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.6);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.6);
  }
  100% {
    transform: scale(1, 1);
  }
}

.modelContent {
  background-color: rgb(255, 255, 255);
  /* height: 250px; */
  margin: 10px;
  border-color: #979797;
  border-style: solid;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
}


.aboutModelContent {
  background-color: rgb(255, 255, 255);
  height: 250px;
  margin: 10px;
  text-align: center;
  padding: 20px;
}
.headerView {
  background-color: rgb(85 85 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  display: flex;
  justify-content: space-between;
  /* padding: 10px 160px 10px 160px; */
  padding-left: 10%;
  padding-right: 10%;
}

.backGroundColor {
  background-color: rgb(85 85 255 / var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
}

.backGroundColor1 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

#outline:hover {
  box-shadow: 2px 1px 6px #979797;
  border-radius: 10;
}



/* Transition to a bigger shadow on hover */
#make-it-slow:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: box-shadow 0.3s ease-in-out;
  border-radius: 10px;

}
.image-item {
  /* padding: 20px 0 20px 20px; */
  /* width: 300px !important; */
}

.container-item {
  /* padding: 0px 100px 0px 100px; */
  margin: 0px 100px 0px 100px;
}



.react-multiple-carousel_arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0,0,0,0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  bottom: 32%;
}

.react-multiple-carousel_arrow--left {
    left: calc(2% + 1px);
}

.react-multiple-carousel_arrow--right {
  right: calc(2% + 1px);
}

.react-multiple-carousel_arrow--right::before {
  content: "\e825";
}
.react-multiple-carousel_arrow--left::before {
  content: "\e824";
}
.react-multiple-carousel_arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}


#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}


.app-container {
  min-height: 100vh;
  padding: 1em 1em;
  display: flex;
  flex-direction: row;
  height: 100vh;
  padding-top: 75px;
}

.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
 
  display: flex;
  /* border-right: #e9e9e9 1px solid; */
  flex-direction: row;
  /* background: #ffffff; */
  box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  z-index: 2;
}

.app-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  height: 100vh;
  max-height: 100%;
  background: #ffffff;
  box-shadow: 8px 2px 32px -2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 10px 10px 0px;
  z-index: 1;
}

.app-sidebar .app-sidebar-content {
  flex: 1;
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 6px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 3px;
  background: #c1c3c5b4;
}


.MuiOutlinedInput-inputAdornedEnd {
  padding-right: 10px !important;
  font-size: 16px;
}

